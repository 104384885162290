import { render, staticRenderFns } from "./Halloween6Modal.vue?vue&type=template&id=31a74c4d&scoped=true&"
import script from "./Halloween6Modal.vue?vue&type=script&lang=js&"
export * from "./Halloween6Modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a74c4d",
  null
  
)

export default component.exports